<template>
    <div>
        <div class="container-fluid">
            <div class="form-group row mb-0" v-show="showCategories">
                <div class="col-md-3 text-center align-items-center" v-for="(category, index) in categories"
                     v-on:click="navigateToGroup(index)">
                    <img class="m-2" style="max-width: 80%;cursor: pointer"
                         :src="'/img/webshop/' + category.id + '.png'">
                </div>
            </div>
            <div v-if="showProductGroups && selectedCategory !== null">
                <a href="#" v-on:click.stop.prevent="navigateToGroup()">
                    <i class="fas fa-chevron-left"></i>
                    Back
                </a>
                <h2>{{ this.selectedCategory.name }}</h2>
                <div><i class="bi bi-house"></i></div>
                <div class="row">
                    <div class="col-6">
                        <dl v-for="(group, index) in returnOddValues(selectedCategory.subgroups)">
                            <dt class="col-sm-3">{{ group.name }}</dt>
                            <div v-for="(subgroup, idx) in group.subgroup_list">
                                <dd class="col-sm-9"><a class="intern-link bold-letter"
                                                        v-bind:href="'/shop/' + idx + '/article-list'"
                                                        target="_self">{{ subgroup.name }}
                                </a>
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div class="col-6">
                        <dl v-for="(group, index) in returnEvenValues(selectedCategory.subgroups)">
                            <dt class="col-sm-3">{{ group.name }}</dt>
                            <div v-for="(subgroup, idx) in group.subgroup_list">
                                <dd class="col-sm-9"><a class="intern-link bold-letter"
                                                        v-bind:href="'/shop/' + idx + '/article-list'"
                                                        target="_self">{{ subgroup.name }}
                                </a>
                                </dd>
                            </div>
                        </dl>
                    </div>

                </div>
            </div>
        </div>
        <div class="overlay" v-show="loading">
            <square-grid></square-grid>
        </div>
    </div>
</template>

<script>
export default {
    props: ['categories'],
    data() {
        return {
            showCategories: true,
            showProductGroups: false,
            loading: false,
            selectedCategory: null
        }
    },
    mounted() {

    },
    methods: {
        navigateToGroup(index = null) {
            if (index === null) {
                this.selectedCategory = null;
                this.showCategories = true;
                this.showProductGroups = false;
            } else {
                this.selectedCategory = this.categories[index];
                this.showCategories = false;
                this.showProductGroups = true;
            }
        },
        returnOddValues(groups) {
            let items = [];
            for (let i = 0; i < groups.length; ++i) {
                if (i % 2 !== 0) {
                    items.push(groups[i]);
                }
            }
            return items;
        },
        returnEvenValues(groups) {
            let items = [];
            for (let i = 0; i < groups.length; ++i) {
                if (i % 2 === 0) {
                    items.push(groups[i]);
                }
            }
            return items;
        }
    }
}
</script>
