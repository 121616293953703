import "core-js/stable";
import "regenerator-runtime/runtime";

window.Vue = require('vue').default;
const VueLang = require('@eli5/vue-lang-js');

import translations from './lang.json';

Vue.use(VueLang, {
    messages: translations,
    locale: window.Laravel.locale,
    fallback: 'en'
});
const files = require.context('./', true, /\.vue$/i);

files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

window.bus = new Vue({});

const app = new Vue({
    el: '.app'
});

Document.prototype.ready = function (callback) {
    if (callback && typeof callback === 'function') {
        document.addEventListener("DOMContentLoaded", function () {
            if (document.readyState === "interactive" || document.readyState === "complete") {
                return callback();
            }
        });
    }
};

require('./bootstrap');
require('./menu.js');
require('./components/flatpicker');
require('./components/choices');
