<template>
    <div>
        <div class="row">
            <div class="productGroupDescription fixed-row">
                <div class="col-sm-3">
                            <img class="productGroupImage"
                                 :src="localItem.options.original_image_url"
                                 alt="No image available">
                </div>
                <div class="col-sm-9 offset-sm-3">
                    <div class="row">
                        <div class="col-sm-11"><h5 class="productName" itemprop="name">{{localItem.name}} </h5></div>
                        <div class="col-sm-1 mt-4"><i class="fa fa-trash fa-lg" style="color:#e6001e" v-on:click="remove(index)"></i></div>
                    </div>

                    <br>
                    <div class="productGroupDescriptionText" itemprop="description">
                        <div v-if="localItem.options.description_html === null" class="text-justify" >{{ localItem.options.description }}</div>
                        <div v-else class="text-justify" v-html="localItem.options.description_html"></div>
                        <br>
                        <ul>
                            <li v-for="option in localItem.options.product_options">
                                {{option.optionName}}: {{option.valueName}}
                            </li>
                        </ul>
                        <h5>{{ Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(parseFloat((localItem.price * localItem.qty).toFixed(2))) }}
                            {{ localItem.options.currency }}</h5>
                    </div>
                    <div v-show="canChangeQuantity">
                        <label for="qty">{{$trans('shop.labels.cart.quantity')}}</label>
                        <input @input="debounceInput" id="qty" type="number" min="1" step="1" v-model="localItem.qty"
                               name="qty">
                        <span>
                             X {{ localItem.options.quantity
                            }} {{localItem.options.measure_unit}}
                        </span>
                    </div>

                    <span v-if="requiresPrintDataCheck && localItem.options.requires_print_data_check" v-model="dataCheckStatus" v-bind:class="printDataCheckStatusClass">{{printDataCheckStatusMessage}}</span>
                    <div>
                        <a v-if="localItem.options.template" :href="localItem.options.template.path" target="_blank">View
                            {{ localItem.options.template.title }}</a>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <div class="overlay" v-show="loading">
            <square-grid></square-grid>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
    export default {
        props: ['item', 'index', 'requiresPrintDataCheck', 'canChangeQuantity'],
        data() {
            return {
                localItem: this.item,
                loading: false,
                printDataCheckStatusClass: '',
                printDataCheckStatusMessage: ''
            }
        },
        watch: {
            item: function (newItem) {
                this.localItem = newItem;
            }
        },
        computed:{
            dataCheckStatus: function () {
                switch (this.localItem.options.print_data_check_status) {
                    case 0: {
                        this.printDataCheckStatusClass = 'alert alert-warning';
                        this.printDataCheckStatusMessage = 'Print data check in progress';
                        return;
                    }
                    case 1: {
                        this.printDataCheckStatusClass = 'alert alert-success';
                        this.printDataCheckStatusMessage = 'Print data check accepted';
                        return;
                    }
                    case 2: {
                        this.printDataCheckStatusClass = 'alert alert-danger';
                        this.printDataCheckStatusMessage = 'Print data check rejected';
                        return;
                    }
                }
            }
        },
        methods: {
            debounceInput: debounce(function (e) {
                this.updateQuantity(this.index, this.localItem.qty);
                this.filterKey = e.target.value;
            }, 1000),
            updateQuantity(index, quantity) {
                let _that = this;
                _that.loading = true;
                let formData = new FormData;
                formData.append('quantity', quantity);
                axios.put(route('cart.update', {'cart': index}), {
                    quantity: quantity
                })
                    .then(function (response) {
                        // window.bus.$emit('removeCartItem', index)
                        _that.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                    });
            },
            remove(index) {
                let _that = this;
                _that.loading = true;
                axios.delete(route('cart.destroy', {'cart': index}))
                    .then(function (response) {
                        window.bus.$emit('removeCartItem', index)
                        _that.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                    });
            },
            submit() {
                let _that = this;
                axios.post(route('cart.submit', {}), {
                    quantity: quantity
                })
                    .then(function (response) {
                        // window.bus.$emit('removeCartItem', index)
                        _that.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                    });
            }
        }
    }
</script>
