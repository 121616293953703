<template>
    <div>
            <h4 class="article-title">{{ group.name }} ({{ variant.quantity.quantity }} STK)</h4>
            <div class="productPrice" itemprop="price">
                {{
                    Intl.NumberFormat('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(variant.quantity[shipping + '_shipping'].price)
                }} {{ variant.quantity.currencySymbol }}
            </div>
            <br>
            <div id="productDescriptionText" itemprop="description">
                <template v-if="group.description_html !== undefined">
                    <div v-html="group.description_html"></div>
                </template>
                <template v-else>
                    <p>{{ group.description }}</p>
                </template>
                <div class="pt-3">
                    <h6>{{ $trans('shop.labels.article.features') }}:</h6>
                    <div v-for="attribute in variant.attributes" :key="attribute.attribute_name">
                        {{ attribute.attribute_name }}: <span v-html="attribute.attribute_value"></span>
                    </div>
                    <div>{{$trans('shop.labels.article.amount') }}: {{ variant.quantity.quantity }} STK</div>
                </div>
                <template v-if="variant.quantity[shipping + '_shipping'].from !== null && variant.quantity[shipping + '_shipping'].to !== null">
                    <div class="pt-3">
                        <h6 class="d-inline-block">{{$trans('shop.labels.article.shipping')}}:</h6> <span>{{ formatDeliveryTimes(variant.quantity[shipping + '_shipping'].from, variant.quantity[shipping + '_shipping'].to) }} {{ $trans('shop.labels.article.shipping_days') }}</span>
                    </div>
                </template>
                <template v-if="variant.quantity[shipping + '_shipping'].deadline !== null">
                    <div class="pt-3">
                        <h6 class="d-inline-block">{{$trans('shop.labels.article.deadline')}}:</h6> <span>{{ variant.quantity[shipping + '_shipping'].deadline }} {{$trans('shop.labels.article.deadline_hour')}}</span>
                    </div>
                </template>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label font-weight-bold">{{$trans('shop.labels.article.shippingType')}}:</label>
                    <div class="col-sm-8">
                        <select class="form-control" id="shipping_type" name="shipping_type" @change="handleChange">
                            <option v-for="shipping in variant.quantity.shippings" :value="shipping.type.toLowerCase()" :selected="shippingType.toLowerCase() === shipping.type.toLowerCase()">
                                {{ shipping.type }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="variant.data_sheet && variant.data_sheet !== ''" class="form-group row">
                    <div class="col-sm-6 mt-2">
                        <a :href="variant.data_sheet" target="_blank" class="btn btn-sm btn-app-color">{{$trans('shop.buttons.downloadDatasheet')}}</a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['variant', 'group', 'shippingType'],
    data() {
        return {
            shipping: 'standard'
        }
    },
    mounted() {
        this.shipping = this.shippingType;
    },
    computed: {
    },
    methods: {
        handleChange(event) {
            if (event.target.value !== '') {
                this.shipping = event.target.value;
            }
        },
        formatDeliveryTimes(from, to) {
            if (from === to) {
                return from
            }
            return from + ' - ' + to;
        }
    }
}
</script>
