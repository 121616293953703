import flatpickr from 'flatpickr'

document.addEventListener('DOMContentLoaded', function () {
    let element = document.querySelector(".date-interval")

    if (element) {
        flatpickr(element, {
            mode: 'range',
            altFormat: 'Y-m-d',
            altInput: true,
            defaultDate: [document.querySelector('.date-interval-start-date').value, document.querySelector('.date-interval-end-date').value],
            onChange: [function (selectedDates) {
                const dateArr = selectedDates.map(date => this.formatDate(date, "Y-m-d"))
                let start = document.querySelector('.date-interval-start-date')
                let end = document.querySelector('.date-interval-end-date')
                start.value = dateArr[0]
                end.value = dateArr[1]
            }]
        })
    }
})
