document.ready(function () {

    let productMenu = document.getElementsByClassName('product')[0];
    if (typeof productMenu === 'undefined') {
        return;
    }
    productMenu.addEventListener('click', function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (!productMenu.classList.contains('is-active')) {
            productMenu.classList.add('is-active');
            document.getElementsByClassName('submenu')[0].classList.add('menu-active');
        } else {
            productMenu.classList.remove('is-active');
            document.getElementsByClassName('submenu')[0].classList.remove('menu-active');
        }

    });

    let navItems = document.querySelectorAll('.nav-main-tabs')[0].children;
    let contentItems = document.querySelectorAll('.subsubmenu')[0].children;

    let subsubmenu = document.getElementsByClassName('subsubmenu')[0];

    for (let counter = 0; counter < navItems.length; counter++) {
        navItems[counter].onclick = function (event) {
            event.stopPropagation();
            event.preventDefault();
        };
        navItems[counter].onmouseover = function () {
            let previousNavItem = getSiblingsOfNavItem(navItems[counter]);
            let previousContentItem = getSiblingsOfContentItem(contentItems[counter]);
            navItems[counter].classList.add('active');
            contentItems[counter].classList.add('sub-menu-active');
            contentItems[counter].classList.remove('sub-menu-not-active');
            subsubmenu.classList.remove('sub-menu-not-active');
            if(previousNavItem.length !== 0) {
                previousNavItem[0].classList.remove('active');
            }

        };
        navItems[counter].onmouseout = function (event) {
            if( event.toElement.classList[0]) {
                if (event.toElement.classList[0] === 'cell') {
                    event.toElement.children[0].children[0].classList.add('sub-menu-active');
                } else {
                    navItems[counter].classList.remove('active');
                    contentItems[counter].classList.remove('sub-menu-active');
                    contentItems[counter].classList.add('sub-menu-not-active');
                }
                getSiblingsOfContentItem(contentItems[counter]);
            }
        };
    }

    let getSiblingsOfNavItem = function (navItem) {
        let siblings = [];
        let sibling = navItem.parentNode.firstChild;

        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== navItem && sibling.classList.contains('active')) {
                siblings.push(sibling);
                sibling.classList.remove('active');
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };

    let getSiblingsOfContentItem = function (navItem) {
        let siblings = [];
        let sibling = navItem.parentNode.firstChild;

        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== navItem && sibling.classList.contains('sub-menu-active')) {
                siblings.push(sibling);
                sibling.classList.remove('sub-menu-active');
                sibling.classList.add('sub-menu-not-active');
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };

});
