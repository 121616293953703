<template>
    <div>
        <div class="container-fluid article-list">
            <h4 class="" itemprop="name">{{ categoryHeader }}</h4>
            <div v-show="initialLoaded" class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="productOptionsGroup">
                        <h5 v-if="Object.keys(filters).length > 0">{{ $trans('shop.labels.article.features') }}</h5>
                        <div v-for="(attributeGroup, key)  in filters" :id=key>
                            <h5>{{ attributeGroup.name }}:</h5>
                            <select @change="addFilter($event, key)" class="form-control form-control-sm"
                                    name="variants">
                                <option value=-1>{{ $trans('shop.labels.article.selectAttribute') }}</option>
                                <option v-for="attribute in Object.values(attributeGroup.values).sort((a,b) => a - b)"
                                        :value="getKeyByValue(attribute, attributeGroup.values)"
                                        :selected="isAttributeSelected(getKeyByValue(attribute, attributeGroup.values))"
                                >{{ attribute }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-8 col-lg-8">
                    <div class="media">
                        <img class="align-self-start mr-3" :src="group.image" :alt="group.name">
                        <div class="media-body pl-3">
                            <h5 class="productName" itemprop="name">
                                {{ group.name }}
                            </h5>
                            <div v-if="!isHtmlDescription(group)" class="text-justify">{{ group.description }}</div>
                            <div v-else class="text-justify" v-html="group.description_html"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="Object.keys( variant ).length > 0">
                <table id="priceTable" class="table table-striped table-bordered table-light">
                    <thead>
                    <tr>
                        <th>Quantity</th>
                        <th>QuantityID</th>
                        <th>Standard<br />
                            <span v-if="standardDeliveryTimes !== null" style="font-weight:normal">
                                <small><i class="fa fa-truck"></i> {{standardDeliveryTimes}} {{ $trans('shop.labels.article.shipping_days') }}</small>
                            </span>
                        </th>
                        <th>Express<br />
                            <span v-if="expressDeliveryTimes !== null" style="font-weight:normal">
                                <small><i class="fa fa-truck"></i> {{expressDeliveryTimes}} {{ $trans('shop.labels.article.shipping_days') }}</small>
                            </span>
                        </th>
                        <th>Overnight<br />
                            <span v-if="overnightDeliveryTimes !== null" style="font-weight:normal">
                                <small><i class="fa fa-truck"></i> {{overnightDeliveryTimes}} {{ $trans('shop.labels.article.shipping_days') }}</small>
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in variant.quantities" v-bind:value="item.id">
                            <td>{{item.quantity}}</td>
                            <td>{{item.id}}</td>
                            <td>
                                <template v-if="getPriceForType(item, 'Standard') !== null">
                                    <a :href="getQuantityRoute(item.id, 'standard')">
                                        {{
                                            Intl.NumberFormat('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }).format(getPriceForType(item, 'Standard'))
                                        }} {{ item.currencySymbol }}
                                    </a>
                                 </template>
                                <template v-else>
                                   -
                                </template>
                            </td>
                            <td>
                                <template v-if="getPriceForType(item, 'Express') !== null">
                                    <a :href="getQuantityRoute(item.id, 'express')">
                                        {{
                                            Intl.NumberFormat('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }).format(getPriceForType(item, 'Express'))
                                        }} {{ item.currencySymbol }}
                                    </a>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td>
                                <template v-if="getPriceForType(item, 'Overnight') !== null">
                                    <a :href="getQuantityRoute(item.id, 'overnight')">
                                        {{
                                            Intl.NumberFormat('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }).format(getPriceForType(item, 'Overnight'))
                                        }} {{ item.currencySymbol }}
                                    </a>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="overlay" v-show="loading">
                <square-grid></square-grid>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['subgroupId', 'group'],
    data() {
        return {
            selectedFilters: {},
            articles: [],
            filters: [],
            loading: false,
            initialLoaded: false,
            variant: [],
            shippingTimes: {
                standardFrom: null,
                standardTo: null,
                expressFrom: null,
                expressTo: null,
                overnightFrom: null,
                overnightTo: null
            }
        }
    },
    mounted() {
        this.filter();
    },
    computed: {
        categoryHeader: function () {
            if (this.group.categories.length > 0) {
                let categoryHeader = this.group.categories[0].name;
                for (let i = 1; i < this.group.categories.length; i++) {
                    categoryHeader += ' > ' + this.group.categories[i].name;
                }
                return categoryHeader;
            }
            return this.group.name;
        },
        standardDeliveryTimes: function() {
            if (this.shippingTimes.standardFrom === null) return null;
            if (this.shippingTimes.standardFrom === this.shippingTimes.standardTo) {
                return this.shippingTimes.standardFrom;
            }
            return this.shippingTimes.standardFrom + " - " + this.shippingTimes.standardTo;
        },
        expressDeliveryTimes: function() {
            if (this.shippingTimes.expressFrom === null) return null;
            if (this.shippingTimes.expressFrom === this.shippingTimes.expressTo) {
                return this.shippingTimes.expressFrom;
            }
            return this.shippingTimes.expressFrom + " - " + this.shippingTimes.expressTo;
        },
        overnightDeliveryTimes: function() {
            if (this.shippingTimes.overnightFrom === null) return null;
            if (this.shippingTimes.overnightFrom === this.shippingTimes.overnightTo) {
                return this.shippingTimes.overnightFrom;
            }
            return this.shippingTimes.overnightFrom + " - " + this.shippingTimes.overnightTo;
        }
    },
    methods: {
        isHtmlDescription(group) {
            return group?.description_html;
        },
        isAttributeSelected(attributeId) {
            return Object.values(this.selectedFilters).indexOf(attributeId) > -1;
        },
        getKeyByValue(attribute, values) {
            for (const [key, value] of Object.entries(values)) {
                if (value === attribute) {
                    return key;
                }
            }
        },
        addFilter(event, attributeGroupKey) {
            let selectedValue = event.target.value;
            if (selectedValue === "-1") {
                if (this.selectedFilters.hasOwnProperty(attributeGroupKey)) {
                    delete this.selectedFilters[attributeGroupKey];
                }
            } else {
                this.selectedFilters[attributeGroupKey] = selectedValue;
            }
            this.filter();
        },
        filter() {
            let _that = this;
            _that.loading = true;
            axios.post(route('shop.attributes'), {
                'subgroupId': this.subgroupId,
                'used_attribute_ids': this.selectedFilters
            })
                .then(function (response) {
                    _that.filters = response.data.data.attributes;
                    if (typeof response.data.data.variants.variant !== "undefined") {
                        _that.variant = response.data.data.variants.variant;
                    } else {
                        _that.variant = [];
                    }
                    _that.loading = false;
                    _that.initialLoaded = true;
                })
                .catch(function (error) {
                    console.log(error);
                    _that.loading = false;
                });
        },
        getQuantityRoute(quantityId, shipping) {
            return route('shop.show', {quantityid: quantityId, shipping: shipping});
        },
        getPriceForType(quantityData, type) {
            const index = quantityData['shippings'].findIndex(item => item.type === type);
            if (index !== -1) {
                if (type === 'Standard' && this.shippingTimes.standardFrom === null) {
                    this.shippingTimes.standardFrom = quantityData['shippings'][index].from;
                    this.shippingTimes.standardTo = quantityData['shippings'][index].to;
                }
                if (type === 'Express' && this.shippingTimes.expressFrom === null) {
                    this.shippingTimes.expressFrom = quantityData['shippings'][index].from;
                    this.shippingTimes.expressTo = quantityData['shippings'][index].to;
                }
                if (type === 'Overnight' && this.shippingTimes.overnightFrom === null) {
                    this.shippingTimes.overnightFrom = quantityData['shippings'][index].from;
                    this.shippingTimes.overnightTo = quantityData['shippings'][index].to;
                }
                return quantityData['shippings'][index].price;
            }
            return null;
        }
    }
}
</script>
