<template>
    <div>
        <div class="container">
            <h3 v-if="groupsArray.length === 0">{{ $trans('shop.labels.search.noResults') }}</h3>
            <div v-else>
                <h3>{{filteredArray.length}} {{ $trans('shop.labels.search.results') }}</h3>
                <select class="custom-select w-25 mb-3" v-on:change="filterCategories">
                    <option value="all">{{ $trans('shop.labels.search.allCategories') }}</option>
                    <option v-for="category in categoryFilter" v-bind:value="category.id">
                        {{ category.name }}
                    </option>
                </select>
                <ul class="list-group">
                    <li v-for="(group, key) in visibleGroups" class="list-group-item clearfix">
                        <img class="img-responsive img-rounded" v-bind:src="group.image" alt=""
                             style="float:left; margin-right:15px; max-width: 240px;"/>
                        <h3 class="list-group-item-heading">
                            <a v-bind:href="getRoute(group.id)">{{ group.name }}</a>
                        </h3>
                        <p class="list-group-item-text lead">
                            {{ group.description.substr(0, 400) + '...' }}
                            <br/>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="text-center mt-3">
                <button class="btn btn-outline-danger" @click="groupsVisible += step"
                        v-if="groupsVisible < filteredArray.length">Load more...
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['groups'],
    data() {
        return {
            groupsVisible: 5,
            step: 5,
            groupsArray: [],
            filteredArray: [],
            categoryFilter: []
        }
    },
    mounted() {
        this.groupsArray = Object.values(this.groups);
        this.filteredArray = this.groupsArray;
        this.createCategoryFilter();
    },
    computed: {
        visibleGroups() {
            return this.filteredArray.slice(0, this.groupsVisible)
        }
    },
    methods: {
        getRoute(id) {
            return route('shop.article_list', {id: id});
        },
        filterCategories: function(evt) {
            let val = evt.target.value;
            if (val === 'all') {
                this.filteredArray = this.groupsArray;
            } else {
                this.filteredArray = this.groupsArray.filter(function (e) {
                    return e.categories[0]['id'] == val;
                })
            }
        },
        createCategoryFilter() {
            let _that = this;
            this.groupsArray.forEach(function (group){
                if(_that.categoryFilter.find(e => e.id === group.categories[0]['id']) === undefined) {
                    _that.categoryFilter.push({id: group.categories[0]['id'], name: group.categories[0]['name']});
                }
            });
        }
    }
}
</script>
